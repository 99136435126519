<template>
  <div>
    <BaseBreadcrumb :title="'Formato de Encuesta'">
      <template v-slot:options-left>
        <v-btn icon small class="mr-2" v-on:click="atras">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:options-right>
        <div>
          <span class="text-caption red--text mr-4">{{ messageFormError }}</span>
          <v-btn v-on:click="save" small color="primary" class="cls-index"> Guardar</v-btn>
        </div>
      </template>
    </BaseBreadcrumb>
    <div style="padding-top: 55px;">
      <v-container fluid class="pa-0" v-if="mostrarFormato && surveyFormat">
        <div class="px-5 mx-10">
          <v-row>
            <v-col :cols="isView ? '12' : '11'">
              <v-form ref="form" v-model="valid" lazy-validation>
                <datos-formato-encuesta
                  :surveyFormat="surveyFormat"
                  :isView="isView"
                  :itemsCampaigns="itemsCampaigns"
                ></datos-formato-encuesta>
                <seccion-formato-encuesta
                  v-for="(section, i) in surveyFormat.sections"
                  :key="i"
                  :position="i"
                  :number="numberInActive(section.id)"
                  :total="sectionsActives.length"
                  :section="section"
                  :typesQuestion="typesQuestion"
                  :showFirstSection="surveyFormat.showFirstSection"
                  :isView="isView"
                >
                </seccion-formato-encuesta>
              </v-form>
            </v-col>
            <v-col cols="1" v-if="!isView">
              <v-card class="text-center barra-formato-encuesta">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mx-2" fab small v-bind="attrs" v-on="on" @click="agregarPregunta">
                      <v-icon small>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Añadir Pregunta</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mx-2" fab small v-bind="attrs" v-on="on" @click="agregarSeccion">
                      <v-icon small>
                        mdi-view-sequential-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Añadir Sección</span>
                </v-tooltip>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import DatosFormatoEncuesta from '@/gquest/encuesta/formato_encuesta/components/DatosFormatoEncuesta';
import SeccionFormatoEncuesta from '@/gquest/encuesta/formato_encuesta/components/SeccionFormatoEncuesta';
import FormatoEncuestaService from '../services/FormatoEncuestaService';
import Swal from 'sweetalert2';

export default {
  name: 'FormatoEncuesta',
  components: {
    DatosFormatoEncuesta,
    SeccionFormatoEncuesta
  },
  computed: {
    surveyFormat() {
      return this.$store.state.surveyFormat;
    },
    selection() {
      return this.$store.state.selection;
    },
    sectionsActives() {
      if (this.$store.state.surveyFormat.sections)
        return this.$store.state.surveyFormat.sections.filter((element) => element.status === true);
      else return [];
    }
  },
  data: () => ({
    typesQuestion: [],
    formatoEncuestaService: null,
    showLoading: false,
    valid: false,
    edit: false,
    mostrarFormato: false,
    isView: null,
    messageFormError: '',
    itemsCampaigns: []
  }),
  methods: {
    async formValide() {
      console.log(this.$refs, 'refs');
      return this.$refs.form ? await this.$refs.form.validate(true) : true;
    },
    agregarSeccion() {
      if (this.surveyFormat.showFirstSection) {
        this.$store.dispatch('addElementoFormato', {
          selection: this.$store.state.selection,
          surveyFormat: this.$store.state.surveyFormat,
          type: 'S',
          typeQuestion: this.typesQuestion[2].id
        });
      } else {
        this.$store.commit('SET_MOSTRAR_PRIMERA_SECCION', true);
      }
    },
    buscarPosicionSeccion(id) {
      let pos = 0;
      let posSection = null;
      this.surveyFormat.sections.forEach((elemento) => {
        if (elemento.id === id) {
          posSection = pos;
        }
        pos++;
      });

      return posSection;
    },
    agregarPregunta() {
      this.$store.dispatch('addElementoFormato', {
        selection: this.$store.state.selection,
        surveyFormat: this.$store.state.surveyFormat,
        type: 'Q',
        typeQuestion: this.typesQuestion[2].id
      });
    },
    numberInActive(id) {
      let number;
      this.sectionsActives.forEach((element, pos) => {
        if (element.id == id) number = pos + 1;
      });
      return number;
    },
    async getTypesQuestion() {
      const res = await this.formatoEncuestaService.get().execResource('tiposPregunta');
      if (res.status) {
        this.typesQuestion = res.data;
      }
    },
    async getCampaign() {
      const res = await this.formatoEncuestaService.get().execResource('listarCampanas');
      if (res.status) {
        this.itemsCampaigns = res.data;
      }
    },
    async initialize() {
      this.getTypesQuestion();
      this.getCampaign();
      let hasPropertyId = Object.prototype.hasOwnProperty.call(this.$route.params, 'id');
      let hasPropertyIdDuplicate = Object.prototype.hasOwnProperty.call(this.$route.query, 'idDuplicate');
      this.isView = Boolean.prototype.hasOwnProperty.call(this.$route.query, 'isView');

      if (hasPropertyId === true || hasPropertyIdDuplicate === true) {
        let id = hasPropertyId ? this.$route.params.id : this.$route.query.idDuplicate;

        this.edit = hasPropertyId;

        this.showLoading = true;
        const res = await this.formatoEncuestaService.get().execResource('obtenerFormatoEncuesta', {
          quiz_id: id
        });
        if (res.status) {
          let surveyFormat = res.data[0];
          let showFirstSection = surveyFormat.sections[0].title === '' ? false : true;
          surveyFormat = { ...surveyFormat, showFirstSection: showFirstSection };
          if (hasPropertyId === true) {
            const fechaInicio = new Date(surveyFormat.startDate + 'T00:00:00');
            const fechaActual = new Date();
            if (fechaInicio <= fechaActual) {
              await this.alertDialog('error', 'La encuesta ya inició, no se puede editar');
              this.$router.push({ name: 'matriz_formato_encuesta' });
            } else {
              this.$store.commit('SET_FORMATO_ENCUESTA', surveyFormat);
              this.$store.commit('RESET_SELECTION_ELEMENT_FORMATO');
            }
          } else this.$store.dispatch('duplicateFormato', surveyFormat);
          this.mostrarFormato = true;
        } else {
          await this.alertDialog('error', res.data);
          this.$router.push({ name: 'matriz_formato_encuesta' });
        }
        this.showLoading = false;
      } else {
        this.mostrarFormato = true;
        //this.$refs.form.resetValidation();
        this.$store.commit('CREATE_FORMATO_ENCUESTA');
      }
    },
    validateNonRepitions() {
      let surveyFormat = { ...this.$store.state.surveyFormat };
      let sections = {};
      let valide = true;
      surveyFormat.sections.forEach((s) => {
        sections[s.title] = (sections[s.title] ?? 0) + 1;
        let questions = {};
        s.questions.forEach((q) => {
          questions[q.title] = (questions[q.title] ?? 0) + 1;
        });
        Object.entries(questions).forEach((el) => {
          if (el[1] > 1) {
            this.messageFormError = 'No se debe repetir los nombres de las preguntas por sección';
            valide = false;
          }
        });
      });
      Object.entries(sections).forEach((el) => {
        if (el[1] > 1) {
          this.messageFormError = 'No se debe repetir los nombres de las secciones';
          valide = false;
        }
      });
      return valide;
    },
    async save() {
      if (this.validateNonRepitions()) {
        if (this.$refs.form.validate(true)) {
          this.messageFormError = '';
          let question = '¿Desea guardar el formulario?';
          if (this.edit) question = '¿Desea actualizar el formulario?';

          let textAlert = '';
          const fechaActual = new Date();
          const fechaInicio = new Date(this.$store.state.surveyFormat.startDate);
          const fechaFin = new Date(this.$store.state.surveyFormat.endDate);
          let typeAlert = 'question';
          if (fechaInicio <= fechaActual) {
            typeAlert = 'warning';
            textAlert = 'Ya no podrá editar la encuesta porque se guardará como una encuesta iniciada';
          }
          if (fechaFin < fechaActual) {
            typeAlert = 'warning';
            textAlert = 'La encuesta se guardará como una encuesta finalizada';
          }
          const { isConfirmed } = await this.alertDialogYesNo(typeAlert, question, textAlert);

          let surveyFormat = { ...this.$store.state.surveyFormat };
          surveyFormat.sections = surveyFormat.sections.filter(
            (section) => section.status == true || section.isNew == false
          );
          surveyFormat.sections = surveyFormat.sections.map((section) => {
            section.questions = section.questions.filter(
              (question) => question.status === true || section.isNew == false
            );
            section.questions = section.questions.map((question) => {
              let typeQuestion = this.typeSelected(question.type);
              if (typeQuestion.multiOptions)
                question.options = question.options.filter(
                  (option) => option.status === true || section.isNew == false
                );
              else question.options = [];
              return question;
            });
            return section;
          });

          if (isConfirmed) {
            if (this.edit) {
              const res = await this.formatoEncuestaService.put().execResource('actualizarFormatoEncuesta', {
                quiz: surveyFormat
              });
              if (res.status) {
                await this.alertDialog('success', res.data);
                this.$router.push({ path: '/encuesta/matriz_formato_encuesta' });
              } else {
                await this.alertDialog('error', res.data);
              }
            } else {
              const res = await this.formatoEncuestaService.post().execResource('guardarFormatoEncuesta', {
                quiz: surveyFormat
              });
              if (res.status) {
                await this.alertDialog('success', res.data);
                this.$router.push({ path: '/encuesta/matriz_formato_encuesta' });
              } else {
                await this.alertDialog('error', res.data);
              }
            }
          }
        } else {
          this.messageFormError = 'Debe ingresar todos los campos requeridos';
        }
      }
    },
    async alertDialogYesNo(type, title, text) {
      return await Swal.fire({
        icon: type,
        title: title,
        text: text,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Guardar`,
        reverseButtons: true
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    atras() {
      this.$router.push({ name: 'matriz_formato_encuesta' });
    },
    typeSelected(id) {
      let type = this.typesQuestion.find((el) => el.id === id);
      return type;
    }
  },
  created() {
    this.formatoEncuestaService = this.$httpService(new FormatoEncuestaService(), this);
    this.initialize();
  }
};
</script>
