<template>
  <div>
    <v-card
      class="mb-5"
      v-on="!isView ? { click: selectQuestion } : {}"
      :ripple="false"
      :shaped="false"
      v-if="question.status === true"
    >
      <div class="card-selected" v-if="questionSelected == question.id"></div>
      <v-card-text class="pb-4">
        <v-row>
          <v-col cols="12" sm="12" md="12" class="pb-0" v-if="questionSelected !== question.id">
            <h2 class="black--text">
              {{ question.title }} <span class="ml-1 red--text" v-if="question.required">*</span>
            </h2>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0" v-if="!isView">
            <v-text-field
              label="Pregunta *"
              filled
              :rules="titleQuestionRules"
              v-model="question.title"
              :ref="'title' + question.id"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="mt-2 pb-0" v-if="!isView">
            <v-select
              :items="typesQuestion"
              @change="changeTypeQuestion($event)"
              v-model="question.type"
              item-text="text"
              item-value="id"
              label="Tipo de Pregunta *"
            >
              <template v-slot:selection="data">
                <v-icon class="mr-2"> {{ data.item.icon }} </v-icon> {{ data.item.text }}
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon class="mr-2"> {{ data.item.icon }} </v-icon> {{ data.item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0" v-if="typeSelected && typeSelected.multiOptions === false">
            <v-text-field class="my-0 py-0" label="Regular" single-line disabled v-model="typeSelected.description">
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            v-if="typeSelected && typeSelected.multiOptions === true"
            class="text-center mt-0"
          >
            <div v-if="typeSelected.value == 'v-radio-group-horizontal' && questionSelected === question.id" fluid>
              <v-row class="py-0">
                <v-col sm="6" md="2" class="py-0">
                  <v-select
                    :items="arregloDe"
                    v-model="escalaDe"
                    @change="setOptionsEscala"
                    item-text="text"
                    label="De"
                  >
                  </v-select>
                </v-col>
                <v-col sm="6" md="2" xs="6" class="py-0">
                  <v-select
                    :items="arregloHasta"
                    v-model="escalaHasta"
                    @change="setOptionsEscala"
                    item-text="text"
                    label="Hasta"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <!--<v-radio-group row>
                <div v-for="(optionQuestion, i) in question.options" :key="i">
                  <v-radio
                    v-if="optionQuestion.status"
                    :label="optionQuestion.title"
                    :value="optionQuestion.title"
                    class="radio-label-top mx-3"
                    disabled
                  ></v-radio>
                </div>
              </v-radio-group>-->
            </div>

            <div
              :class="
                typeSelected.value == 'v-radio-group-horizontal' && questionSelected !== question.id ? 'd-flex' : ''
              "
            >
              <!-- :class="!(typeSelected.value === 'v-radio-group-horizontal' && questionSelected !== question.id) ? 'col-12' : ''" -->
              <v-row>
                <div
                  class="col mt-9"
                  v-if="typeSelected.value == 'v-radio-group-horizontal' && questionSelected !== question.id"
                >
                  {{ question.options[firstPositionOptionActive].title }}
                </div>
                <div
                  v-for="(optionQuestion, i) in question.options"
                  :key="i"
                  :class="[
                    typeSelected.value !== 'v-radio-group-horizontal'
                      ? 'col-12'
                      : questionSelected === question.id &&
                        (i == firstPositionOptionActive || i == question.options.length - 1)
                      ? 'col-12'
                      : questionSelected !== question.id
                      ? 'col'
                      : '',
                    optionQuestion.status ? '' : 'pa-0'
                  ]"
                >
                  <div
                    align-self="center"
                    :class="typeSelected.value == 'v-radio-group-horizontal' ? 'radio-label-top text-center' : ''"
                    class="text-left mt-2 d-flex"
                    v-if="optionQuestion.status && questionSelected !== question.id"
                  >
                    <div class="mt-1">
                      <v-icon
                        v-if="
                          typeSelected.value == 'v-radio-group' ||
                            typeSelected.value == 'v-checkbox' ||
                            typeSelected.value == 'v-radio-group-horizontal'
                        "
                      >
                        {{
                          typeSelected.value == 'v-radio-group' || typeSelected.value == 'v-radio-group-horizontal'
                            ? 'mdi-checkbox-blank-circle-outline'
                            : 'mdi-checkbox-blank-outline'
                        }}
                      </v-icon>
                      <span v-if="typeSelected.value == 'v-radio-group-horizontal'" class="mt-1 text-center">{{
                        optionQuestion.position
                      }}</span>
                      <span v-if="typeSelected.value == 'v-select'">{{ i + 1 + '.' }}</span>
                      <span class="ml-3 black--text" v-if="typeSelected.value !== 'v-radio-group-horizontal'">{{
                        question.options[i].title
                      }}</span>
                    </div>
                    <div
                      v-if="
                        (typeSelected.value == 'v-radio-group' ||
                          typeSelected.value == 'v-checkbox' ||
                          typeSelected.value == 'v-radio-group-horizontal') &&
                          question.options[i].hasComment
                      "
                    >
                      <v-divider vertical class="ml-5"></v-divider>
                      <v-icon class="ml-5" style="margin-top: -15px;">
                        mdi-keyboard-settings
                      </v-icon>
                    </div>
                  </div>
                  <div v-if="optionQuestion.status && questionSelected === question.id">
                    <v-text-field
                      class="my-0 py-0 text-grey"
                      :ref="idSection + question.id + i"
                      v-model="question.options[i].title"
                      v-if="
                        (typeSelected.value == 'v-radio-group-horizontal' &&
                          (i == firstPositionOptionActive || i == question.options.length - 1)) ||
                          typeSelected.value != 'v-radio-group-horizontal'
                      "
                      :label="typeSelected.value != 'v-radio-group-horizontal' ? 'Opción' : 'Etiqueta (opcional)'"
                      single-line
                      :append-outer-icon="
                        questionSelected === question.id && typeSelected.value != 'v-radio-group-horizontal'
                          ? 'mdi-close'
                          : ''
                      "
                      @click:append-outer="borrarOpcion(optionQuestion.id)"
                      @focus="$event.target.select()"
                      :append-icon="question.options[i].hasComment ? 'mdi-keyboard-settings' : ''"
                    >
                      <template v-slot:prepend>
                        <v-icon v-if="typeSelected.value == 'v-radio-group' || typeSelected.value == 'v-checkbox'">
                          {{
                            typeSelected.value == 'v-radio-group'
                              ? 'mdi-checkbox-blank-circle-outline'
                              : 'mdi-checkbox-blank-outline'
                          }}
                        </v-icon>
                        <!--<v-icon v-if="typeSelected.value == 'v-radio-group-horizontal'">
                          {{
                            'mdi-checkbox-blank-circle-outline'
                          }}
                        </v-icon>-->
                        <span v-if="typeSelected.value == 'v-radio-group-horizontal'" class="mt-1">{{
                          optionQuestion.position + '.'
                        }}</span>
                        <span v-if="typeSelected.value == 'v-select'" class="mt-1">{{ i + 1 + '.' }}</span>
                      </template>
                    </v-text-field>
                  </div>
                </div>
                <div
                  class="col mt-9"
                  v-if="typeSelected.value == 'v-radio-group-horizontal' && questionSelected !== question.id"
                >
                  {{ question.options[question.options.length - 1].title }}
                </div>
              </v-row>
              <v-btn
                v-if="
                  typeSelected.multiOptions === true &&
                    questionSelected === question.id &&
                    typeSelected.value !== 'v-radio-group-horizontal'
                "
                v-on:click="addOption(false)"
                elevation="1"
              >
                Agregar opcion
              </v-btn>
              <a
                class="ml-2"
                v-if="
                  (typeSelected.value === 'v-checkbox' || typeSelected.value === 'v-radio-group') &&
                    questionSelected === question.id &&
                    !hasComment(question)
                "
                v-on:click="addOption(true)"
                >Agregar opcion "Otros"</a
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider v-if="!isView"></v-divider>
      <v-card-actions class="py-0" v-if="!isView">
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="number > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="changeOrder('U')">
              <v-icon>
                mdi-chevron-double-up
              </v-icon>
            </v-btn>
          </template>
          <span>Desplazar arriba</span>
        </v-tooltip>

        <v-tooltip bottom v-if="number < total">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="changeOrder('D')">
              <v-icon>
                mdi-chevron-double-down
              </v-icon>
            </v-btn>
          </template>
          <span>Desplazar abajo</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="duplicar">
              <v-icon>
                mdi-content-copy
              </v-icon>
            </v-btn>
          </template>
          <span>Duplicar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="borrar">
              <v-icon>
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>

        <div class="mx-3">
          |
        </div>
        <v-switch v-model="question.required" :label="`Obligatorio`"></v-switch>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import FormatoEncuestaService from '../services/FormatoEncuestaService';

export default {
  name: 'PreguntaFormatoEncuesta',
  props: {
    position: {
      type: Number,
      default: 1
    },
    number: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    question: {
      type: Object,
      default: null
    },
    idSection: {
      type: String,
      default: null
    },
    posSection: {
      type: Number,
      default: null
    },
    typesQuestion: {
      type: Array,
      default: () => []
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formatoEncuestaService: null,
    titleQuestionRules: [(v) => !!v || 'El título de la sección es obligatorio'],
    arregloDe: [0, 1],
    arregloHasta: [2, 3, 4, 5, 6, 7, 8, 9, 10],
    escalaDe: null,
    escalaHasta: null,
    typeQuestionAux: 'v-radio-group'
  }),
  computed: {
    firstPositionOptionActive() {
      let position = 0;
      position = this.question.options.find((option) => option.status === true);
      position = this.question.options.indexOf(position);
      return position;
    },
    lastPositionOptionActive() {
      return 1;
    },
    questionSelected() {
      let idSeleccionado = this.$store.state.selection.id;
      let tipoSeleccion = this.$store.state.selection.type;
      return tipoSeleccion == 'Q' ? idSeleccionado : null;
    },
    typeSelected() {
      let type = this.typesQuestion.find((el) => el.id === this.question.type);
      return type;
    }
  },
  methods: {
    changeTypeQuestion() {
      if (
        !(
          (this.typeQuestionAux == 'v-radio-group' ||
            this.typeQuestionAux == 'v-checkbox' ||
            this.typeQuestionAux == 'v-select') &&
          (this.typeSelected.value == 'v-radio-group' ||
            this.typeSelected.value == 'v-checkbox' ||
            this.typeSelected.value == 'v-select')
        )
      ) {
        console.log('entra al reseteo');
        this.$store.commit('RESET_OPTIONS_PREGUNTA', {
          posSection: this.posSection,
          posQuestion: this.position
        });
      } else {
        console.log('no entra al reseteo');
      }

      if (this.typeSelected.value == 'v-radio-group-horizontal') {
        this.setOptionsEscala();
      }
      this.typeQuestionAux = this.typeSelected.value;
    },
    selectQuestion() {
      //if (this.$refs['title' + this.question.id]) this.$refs['title' + this.question.id].focus();
      this.$store.commit('SET_FORMATO_SELECCION', {
        type: 'Q',
        id: this.question.id,
        position: this.position,
        idParent: this.idSection
      });
    },
    setOptionsEscala() {
      this.$store.commit('SET_OPTIONS_ESCALA', {
        de: this.escalaDe,
        hasta: this.escalaHasta,
        posSection: this.posSection,
        posQuestion: this.position
      });
    },
    /*addOption() {
      this.$store.commit('ADD_OPTION_PREGUNTA', { posSection: this.posSection, posQuestion: this.position });
      let refOption = this.idSection + this.question.id + (this.question.options.length - 1);
      this.$nextTick(() => {
        this.$refs[refOption][0].focus();
        this.$refs[refOption][0].selectionStart = 0;
        this.$refs[refOption][0].selectionEnd = 10;
      });
    },*/
    addOption(hasComment) {
      this.$store.commit('ADD_OPTION_PREGUNTA', {
        posSection: this.posSection,
        posQuestion: this.position,
        hasComment: hasComment
      });
      let refOption = this.idSection + this.question.id + (this.question.options.length - 1);
      this.$nextTick(() => {
        this.$refs[refOption][0].focus();
        this.$refs[refOption][0].selectionStart = 0;
        this.$refs[refOption][0].selectionEnd = 10;
      });
    },
    hasComment(question) {
      const optionHasComment = question.options.find((element) => element.hasComment && element.status);
      return optionHasComment != null;
    },
    /*async initialize() {
      const res = await this.formatoEncuestaService.get().execResource('tiposEncuesta');

      if (res.status) {
        this.typesQuestion = res.data;
      }
    },*/
    changeOrder(direction) {
      let surveyFormat = this.$store.state.surveyFormat;
      this.$store.dispatch('changeOrderElementoFormato', {
        type: 'Q',
        direction: direction,
        element: this.question,
        surveyFormat: surveyFormat
      });
    },
    duplicar() {
      this.$store.dispatch('duplicateElementoFormato', {
        type: 'Q',
        element: this.question,
        surveyFormat: this.$store.state.surveyFormat,
        posParent: this.posSection
      });
    },
    borrar() {
      this.$store.dispatch('deleteElementoFormato', {
        type: 'Q',
        id: this.question.id,
        idParent: this.question.idParent,
        surveyFormat: this.$store.state.surveyFormat,
        selection: this.$store.state.selection
      });
    },
    borrarOpcion(id) {
      this.$store.dispatch('deleteElementoFormato', {
        type: 'O',
        id: id,
        idParent: this.question.id,
        surveyFormat: this.$store.state.surveyFormat,
        selection: this.$store.state.selection
      });
    },
    cambioEscalaDe() {
      console.log('cambio escala de: ');
    },
    cambioEscalaHasta() {
      console.log('cambio escala hasta: ');
    }
  },
  created() {
    this.formatoEncuestaService = this.$httpService(new FormatoEncuestaService(), this);
    if (Object.prototype.hasOwnProperty.call(this.$route.params, 'id')) {
      this.escalaDe = this.question.options[0].position;
      this.escalaHasta = this.question.options[this.question.options.length - 1].position;
    } else {
      this.escalaDe = 1;
      this.escalaHasta = 5;
    }
    //this.initialize();
  }
};
</script>
