<template>
  <div>
    <v-card class="mb-5">
      <v-card-text>
        <!--<v-text-field
          placeholder="Titulo del Formulario"
          required
          ref="title"
          v-model="surveyFormat.title"
          class="large justify-start"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn text icon color="blue lighten-2"  <<>
          <v-icon>
            mdi-arrow-expand
          </v-icon>
        </v-btn> -->
        <v-row>
          <v-col cols="12" class="mr-auto py-0">
            <v-text-field
              placeholder="Titulo del Formulario *"
              :rules="titleSurveyFormatRules"
              required
              :disabled="isView"
              ref="titleFormulario"
              v-model="surveyFormat.title"
              @focus="$event.target.select()"
              class="large justify-start"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5" sm="5" class="mr-auto py-0">
            <v-select
              :items="itemsCampaigns"
              :disabled="isView"
              :rules="campaignRules"
              @change="changeCampaign()"
              v-model="surveyFormat.campaignId"
              item-value="campaignId"
              item-text="description"
              label="Campaña *"
            >
            </v-select>
            <!--<v-text-field
              label="Campaña *"
              required
              :disabled="isView"
              v-model="surveyFormat.campaign"
              :rules="campaignRules"
            ></v-text-field>-->
          </v-col>
          <v-col cols="12" md="4" sm="4" class="mr-auto py-0">
            <v-radio-group v-model="surveyFormat.isGlobal" row>
              <v-radio label="Por Empresa" :value="false"></v-radio>
              <v-radio label="Por Coorporación" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="mr-auto py-0">
            <v-checkbox v-model="surveyFormat.isAnonymous" :label="'Permite Anónimos'"></v-checkbox>
          </v-col>
          <!--<v-col cols="auto mt-4">
            <v-btn text icon color="blue lighten-2">
              <v-icon>
                mdi-unfold-less-horizontal
              </v-icon>
            </v-btn>
            <v-btn text icon color="blue lighten-2">
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </v-col>-->
          <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
            <v-text-field
              label="Fecha Inicio *"
              type="date"
              :rules="[startDateRules, periodCampaignRules(surveyFormat.startDate), intervalDatesRules()]"
              :disabled="isView || surveyFormat.campaignId == null"
              required
              v-model="surveyFormat.startDate"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
            <v-text-field
              label="Fecha Fin *"
              type="date"
              :rules="[endDateRules, periodCampaignRules(surveyFormat.endDate), intervalDatesRules()]"
              :disabled="isView || surveyFormat.campaignId == null"
              required
              v-model="surveyFormat.endDate"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mr-auto py-0">
            <v-text-field
              label="Descripcion del Formulario"
              :disabled="isView"
              required
              v-model="surveyFormat.description"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'DatosFormatoEncuesta',
  props: {
    surveyFormat: {
      type: Object,
      default: null
    },
    isView: {
      type: Boolean,
      default: null
    },
    itemsCampaigns: {
      type: Array,
      default: new Array()
    }
  },
  data: () => ({
    checkbox: false,
    titleSurveyFormatRules: [
      (v) =>
        !!v ||
        'Titulo del Formulario es oblitagorio' /*,
      (v) => (v && v.length <= 10) || 'Name must be less than 10 characters'*/
    ],
    campaignRules: [(v) => !!v || 'La campaña es obligatoria'],
    startDateRules: (v) => !!v || 'La fecha inicial es obligatoria',
    endDateRules: (v) => !!v || 'La fecha final es obligatoria',
    campaignSelected: null
  }),
  methods: {
    periodCampaignRules(val) {
      if (this.campaignSelected) {
        return () =>
          (val >= this.campaignSelected.startPeriod && val <= this.campaignSelected.endPeriod) ||
          'La fecha debe estar dentro del periodo de la campaña (' +
            this.campaignSelected.startPeriod +
            ' / ' +
            this.campaignSelected.endPeriod +
            ')';
      }
      return true;
    },
    intervalDatesRules() {
      return () =>
        this.surveyFormat.startDate <= this.surveyFormat.endDate || 'La fecha inicio debe ser menor a la fecha fin';
    },
    changeCampaign() {
      this.campaignSelected = this.itemsCampaigns.find((el) => el.campaignId == this.surveyFormat.campaignId);
      this.surveyFormat.startDate = this.campaignSelected.startPeriod;
      this.surveyFormat.endDate = this.campaignSelected.endPeriod;
    }
  }
};
</script>
