class FormatoEncuestaService {
  constructor() {}
  resources = () => ({
    tiposPregunta: {
      uri: `/v1/types`,
      method: ['get']
    },
    guardarFormatoEncuesta: {
      uri: `/v1/quiz`,
      method: ['post']
    },
    actualizarFormatoEncuesta: {
      uri: `/v1/quiz`,
      method: ['put']
    },
    obtenerFormatoEncuesta: {
      uri: `/v1/quiz`,
      method: ['get']
    },
    listarFormatosEncuesta: {
      uri: `/v1/listquiz`,
      method: ['get']
    },
    borrarFormatoEncuesta: {
      uri: `/v1/quiz`,
      method: ['delete']
    },
    listaUsuarios: {
      uri: `/v1/user`,
      method: ['get']
    },
    listaUsuariosAsignados: {
      uri: `/v1/listquizassignedto`,
      method: ['get']
    },
    guardarAsignacionEncuestas: {
      uri: `/v1/assignedto`,
      method: ['post']
    },
    reporteRespuestasEncuesta: {
      uri: `/v1/reportquiz`,
      method: ['get']
    },
    exportarRespuestasEncuesta: {
      uri: `/v1/exportreportquiz`,
      method: ['get']
    },
    listarCampanas: {
      uri: `/v1/listcampaign`,
      method: ['get']
    }
  });
}
export default FormatoEncuestaService;
