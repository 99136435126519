<template>
  <div v-if="section.status === true">
    <div v-if="(showFirstSection || totalsections > 1) && section !== null">
      <div class="header-section" color="primary">Sección {{ number }} de {{ total }}</div>
      <v-card class="mb-5" v-on="!isView ? { click: seleccionarSeccion } : {}">
        <div class="card-selected" v-if="sectionSelected === section.id && !isView"></div>
        <v-card-text>
          <div class="d-flex mb-0">
            <h2 class="black--text" v-if="sectionSelected !== section.id || isView">
              {{ section.title }} <span class="ml-1 red--text" v-if="section.required">*</span>
            </h2>
            <v-text-field
              placeholder="Titulo de la Sección *"
              required
              ref="titleSection"
              :rules="titleSectionRules"
              class="large"
              @focus="$event.target.select()"
              v-else
              v-model="section.title"
            ></v-text-field>
            <v-spacer></v-spacer>
            <div v-if="!isView">
              <v-tooltip bottom v-if="number > 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="changeOrder('U')">
                    <v-icon>
                      mdi-chevron-double-up
                    </v-icon>
                  </v-btn>
                </template>
                <span>Desplazar arriba</span>
              </v-tooltip>
              <v-tooltip bottom v-if="number < total">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="changeOrder('D')">
                    <v-icon>
                      mdi-chevron-double-down
                    </v-icon>
                  </v-btn>
                </template>
                <span>Desplazar abajo</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="duplicar">
                    <v-icon>
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </template>
                <span>Duplicar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" v-on:click="borrar">
                    <v-icon>
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </div>
            <!--<v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text icon color="blue lighten-2">
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>-->
          </div>
          <v-text-field
            v-if="sectionSelected === section.id && !isView"
            label="Descripcion de la Sección"
            class="pt-0"
            required
            v-model="section.description"
          ></v-text-field>
          <span v-else-if="section.description || isView"> {{ section.description }} </span>
          <span v-else> Descripción (Opcional) </span>
        </v-card-text>
      </v-card>
    </div>
    <pregunta-formato-encuesta
      v-for="(question, i) in section.questions"
      :key="i"
      :number="numberInActive(question.id)"
      :total="questionsActives.length"
      :position="i"
      :question="question"
      :idSection="section.id"
      :posSection="position"
      :typesQuestion="typesQuestion"
      :isView="isView"
    >
    </pregunta-formato-encuesta>
  </div>
</template>

<script>
import PreguntaFormatoEncuesta from './PreguntaFormatoEncuesta';

export default {
  name: 'SeccionFormatoEncuesta',
  components: {
    PreguntaFormatoEncuesta
  },
  props: {
    position: {
      type: Number,
      default: 1
    },
    number: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    section: {
      type: Object,
      default: null
    },
    showFirstSection: {
      type: Boolean,
      default: false
    },
    typesQuestion: {
      type: Array,
      default: () => []
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalsections() {
      return this.total;
    },
    sectionSelected() {
      let idSeleccionado = this.$store.state.selection.id;
      let tipoSeleccion = this.$store.state.selection.type;
      return tipoSeleccion == 'S' ? idSeleccionado : null;
    },
    questionsActives() {
      return this.section.questions.filter((element) => element.status === true);
    }
  },
  data: () => ({
    tituloSeccion: '',
    descripcionSeccion: '',
    items: [{ title: 'Borrar' }],
    titleSectionRules: [(v) => !!v || 'El título de la sección es obligatorio']
  }),
  methods: {
    seleccionarSeccion() {
      this.$store.commit('SET_FORMATO_SELECCION', {
        type: 'S',
        id: this.section.id,
        position: this.section.position,
        idParent: null
      });
    },
    changeOrder(direction) {
      let surveyFormat = this.$store.state.surveyFormat;
      this.$store.dispatch('changeOrderElementoFormato', {
        type: 'S',
        direction: direction,
        element: this.section,
        surveyFormat: surveyFormat
      });
    },
    duplicar() {
      //let surveyFormat = this.$store.state.surveyFormat;
      this.$store.dispatch('duplicateElementoFormato', {
        type: 'S',
        element: this.section,
        surveyFormat: this.$store.state.surveyFormat,
        posParent: 0
      });
    },
    borrar() {
      this.$store.dispatch('deleteElementoFormato', {
        type: 'S',
        id: this.section.id,
        idParent: this.section.idParent,
        surveyFormat: this.$store.state.surveyFormat,
        selection: this.$store.state.selection
      });
    },
    numberInActive(id) {
      let number;
      this.questionsActives.forEach((element, pos) => {
        if (element.id == id) number = pos + 1;
      });
      return number;
    }
  }
};
</script>
