var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.section.status === true)?_c('div',[((_vm.showFirstSection || _vm.totalsections > 1) && _vm.section !== null)?_c('div',[_c('div',{staticClass:"header-section",attrs:{"color":"primary"}},[_vm._v("Sección "+_vm._s(_vm.number)+" de "+_vm._s(_vm.total))]),_c('v-card',_vm._g({staticClass:"mb-5"},!_vm.isView ? { click: _vm.seleccionarSeccion } : {}),[(_vm.sectionSelected === _vm.section.id && !_vm.isView)?_c('div',{staticClass:"card-selected"}):_vm._e(),_c('v-card-text',[_c('div',{staticClass:"d-flex mb-0"},[(_vm.sectionSelected !== _vm.section.id || _vm.isView)?_c('h2',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.section.title)+" "),(_vm.section.required)?_c('span',{staticClass:"ml-1 red--text"},[_vm._v("*")]):_vm._e()]):_c('v-text-field',{ref:"titleSection",staticClass:"large",attrs:{"placeholder":"Titulo de la Sección *","required":"","rules":_vm.titleSectionRules},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.section.title),callback:function ($$v) {_vm.$set(_vm.section, "title", $$v)},expression:"section.title"}}),_c('v-spacer'),(!_vm.isView)?_c('div',[(_vm.number > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.changeOrder('U')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-double-up ")])],1)]}}],null,false,209979840)},[_c('span',[_vm._v("Desplazar arriba")])]):_vm._e(),(_vm.number < _vm.total)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.changeOrder('D')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-double-down ")])],1)]}}],null,false,2130507238)},[_c('span',[_vm._v("Desplazar abajo")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.duplicar}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-content-copy ")])],1)]}}],null,false,2580372405)},[_c('span',[_vm._v("Duplicar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.borrar}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1)]}}],null,false,1135642602)},[_c('span',[_vm._v("Eliminar")])])],1):_vm._e()],1),(_vm.sectionSelected === _vm.section.id && !_vm.isView)?_c('v-text-field',{staticClass:"pt-0",attrs:{"label":"Descripcion de la Sección","required":""},model:{value:(_vm.section.description),callback:function ($$v) {_vm.$set(_vm.section, "description", $$v)},expression:"section.description"}}):(_vm.section.description || _vm.isView)?_c('span',[_vm._v(" "+_vm._s(_vm.section.description)+" ")]):_c('span',[_vm._v(" Descripción (Opcional) ")])],1)],1)],1):_vm._e(),_vm._l((_vm.section.questions),function(question,i){return _c('pregunta-formato-encuesta',{key:i,attrs:{"number":_vm.numberInActive(question.id),"total":_vm.questionsActives.length,"position":i,"question":question,"idSection":_vm.section.id,"posSection":_vm.position,"typesQuestion":_vm.typesQuestion,"isView":_vm.isView}})})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }